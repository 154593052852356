import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const TitleStyled = styled(Title)`
font-size: 40px;
line-height 40px;
`

const Content = (props) => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="6">
            <TitleStyled variant="card">{props.mission}</TitleStyled>
          </Col>
          <Col lg="6" className="pl-lg-5">
            <Text>{props.missiontext}</Text>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg="4" sm="5" className="mb-4 ">
            <ContentImg>
              <PreviewCompatibleImage
                imageInfo={{
                  image: props.image1,
                }}
              />
            </ContentImg>
          </Col>
          <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <PreviewCompatibleImage
                imageInfo={{
                  image: props.image2,
                }}
              />
            </ContentImg>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content;
